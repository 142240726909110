@import "variables";

/*******************************************************
Product list Styles
********************************************************/
body#index #center_column{
  padding-top: 0;
}

.ajax_block_product{
  padding-left: 15px;
  padding-right: 15px;
}

@media(max-width:420px){
  .ajax_block_product{
    width: 100%;
  }
}


@media(min-width:1200px){
  .ajax_block_product:nth-child(4n+1){
    clear: both;
  }
}
@media(min-width:992px) and (max-width:1199px){
  .ajax_block_product:nth-child(3n+1){
    clear: both;
  }
}
@media(min-width:421px) and (max-width:991px){
  .ajax_block_product:nth-child(2n+1){
    clear: both;
  }
}



.compare-form,
.bt_layered_form,
.showall,
.productsSortForm{
	display: inline-block;
	margin-left: 15px;
	margin-bottom: 15px;
}


.btn-show-all{
	background: lighten(theme-color(black),30%);
	border: none;
	border-radius:25px;
	color: theme-color(white);
	padding:12px 20px;
	cursor: pointer;
	vertical-align: middle;
	-moz-transition:all linear .2s;
	-ms-transition:all linear .2s;
	-o-transition:all linear .2s;
	-webkit-transition:all linear .2s;
	transition:all linear .2s;
}
.btn-show-all:hover{
	background: lighten(theme-color(black),20%);
}
.bt_compare,
.bt_layered{
	background: theme-color(secondary);
	border: none;
	border-radius:25px;
	color: theme-color(white);
	padding:12px 15px;
	cursor: pointer;
	vertical-align: middle;
	-moz-transition:all linear .2s;
	-ms-transition:all linear .2s;
	-o-transition:all linear .2s;
	-webkit-transition:all linear .2s;
	transition:all linear .2s;
}

@media(max-width:991px){
	.sortPagiBar{
		border-top:  lighten(theme-color(black),70%) solid 1px;
		border-bottom:  lighten(theme-color(black),70%) solid 1px;
		padding:0;
		margin-top: 15px;
		margin-bottom: 15px;
	}
	.btn-show-all,
	.bt_compare,
	.bt_layered,
	.selectProductSort{
		border-radius:0 !important;
		margin: 0;
		border: none;
	}
	.selectProductSort:focus{
		border: none;
	}
	.compare-form,
	.bt_layered_form,
	.showall,
	.productsSortForm{
		margin: 0;
		float: left;
	}
	.selectProductSort{
		transform: none;
	}
}

@media(max-width:625px){
	.btn-show-all,
	.bt_compare,
	.bt_layered,
	.selectProductSort{
		width: 100%;
	}
	.compare-form,
	.bt_layered_form,
	.showall{
		width: 50%;
	}
	.btn-layered-full{
		width: 100%;
	}
	.productsSortForm{
		width: 100%;
		clear: both;
	}
	.selectProductSort{
		background-color: theme-color(white);
		width: 100% !important;
		background-position:  95% center;
	}
}

@media(max-width:425px){
	.btn-show-all,
	.bt_compare,
	.bt_layered_form:not(.btn-layered-full) .bt_layered{
		height: 65px;
	}
}


#product_list{
	padding-top: 15px;
}


.product_list{
	padding: 0;
	list-style-type: none;
}
.product-price{
	color: theme-color(secondary);
	font-size: 20px;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
}
.old-price{
	font-size: 16px;
	text-decoration: line-through;
	color: lighten(theme-color(black),30%);
	font-family: filson-pro, sans-serif;
    font-weight: 300;
}

/************************* cartouche promo ************************/
.price-percent-reduction,
#reduction_percent_display,
#reduction_amount_display{
	width: 70px;
	position: absolute;
	top: 20px;
	right: 10px;
	z-index: 20;
	background: theme-color(secondary);
	color: theme-color(white);
	font-size: 16px;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
	padding: 8px;
	text-align: center;
	line-height: 1.1;
	-moz-box-shadow: -3px 3px 0px 0px rgba(0,0,0,0.2);
	-webkit-box-shadow: -3px 3px 0px 0px rgba(0,0,0,0.2);
	-o-box-shadow: -3px 3px 0px 0px rgba(0,0,0,0.2);
	box-shadow: -3px 3px 0px 0px rgba(0,0,0,0.2);
}

.price-percent-reduction:after,
#reduction_percent_display:after,
#reduction_amount_display:after{
	position: absolute;
	bottom: -5px;
	right: 0;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px 5px 0 0;
	border-color: darken(theme-color(secondary),20%) transparent transparent transparent;
}
.price-percent-reduction span,
#reduction_percent_display span,
#reduction_amount_display span{
	display: block;
	font-size: 10px;
	text-transform: uppercase;
	font-family: filson-pro, sans-serif;
    font-weight: 500;
}


/*************************** cartouche nouveau ***********************/
.new-box{
	display: block;
	width: 87px;
	position: absolute;
	top: 20px;
	left: 10px;
	z-index: 20;
	background: lighten(theme-color(black),20%);
	color: theme-color(white);
	font-size: 13px;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
	padding: 8px;
	text-align: center;
	line-height: 1.1;
	-moz-box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.2);
	-webkit-box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.2);
	-o-box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.2);
	box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.2);
}
 .new-box span{
	display: block;
	font-size: 10px;
	text-transform: uppercase;
	font-family: filson-pro, sans-serif;
    font-weight: 500;
}
.new-box:after{
	position: absolute;
	bottom: -5px;
	left: 0;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 5px 0;
	border-color: transparent lighten(theme-color(black),30%) transparent transparent;
}


/*********************************************************************/
.reference{
	color: lighten(theme-color(black),30%);
	font-size: 14px;
	font-family: filson-pro, sans-serif;
    font-weight: 300;
	line-height: 1;
	margin-bottom: 10px;
}
.product_list h3,
.product_list .h3{
	padding: 0;
	margin: 0;
}
.product_list .product-name {
	display: inline-block;
	font-size: 15px;
	color: lighten(theme-color(black),20%);
	width: 100%;
	min-height:66px;
	font-family: filson-pro, sans-serif;
    font-weight: 500;
}

.availability{
	display: block;
}

.product_list .availability span,
.cart_avail span{
	display: inline-block;
	color: theme-color(green);
	font-size: 12px;
	text-transform: uppercase;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
}
.product_list .availability span:before,
.cart_avail span:before{
	content: "\f00c";
	font-family:'FontAwesome';
	display: inline-block;
	margin-right: 3px;
}

.rupture,
.rupture *{
	font-style: normal !important;
	color: theme-color(red) !important;
}

.product_list .color-list-container {
  margin-bottom: 12px; }
  .product_list .color-list-container ul li {
    display: inline-block;
    border: 1px solid lighten(theme-color(black),75%);
    width: 26px;
    height: 26px; }
    .product_list .color-list-container ul li a {
      display: block;
      width: 22px;
      height: 22px;
      margin: 1px; }
      .product_list .color-list-container ul li a img {
        display: block;
        width: 22px;
        height: 22px; }
.product_list .product-image-container {
	text-align: center;
	position: relative;
}
.product_list .product-image-container .product_img_link{
	display: block;
}
.product_list .product-image-container .img_manu{
	position: absolute!important;
	top: auto !important;
	left: auto !important;
	bottom: 0 !important;
	right: 0 !important;
	opacity: 1 !important;
	z-index: 10!important;
	width: 100px;
}
  .product_list .product-image-container img {
    margin: 0 auto; }
  .product_list .product-image-container .quick-view-wrapper-mobile {
    display: none; }
    .product_list .product-image-container .quick-view-wrapper-mobile .quick-view-mobile {
      display: none; }
      @media (max-width: 1199px) {
        .product_list .product-image-container .quick-view-wrapper-mobile .quick-view-mobile {
          display: block;
          position: relative;
          background-color: rgba(lighten(theme-color(black),30%), 0.57);
          height: 130px;
          width: 85px;
          top: 80px;
          right: -162px;
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          zoom: 1;
          pointer-events: all; }
          .product_list .product-image-container .quick-view-wrapper-mobile .quick-view-mobile i {
            position: relative;
            top: 48px;
            left: -20px;
            font-size: x-large;
            color:theme-color(black);
          }
          .product_list .product-image-container .quick-view-wrapper-mobile .quick-view-mobile i:before {
            -moz-transform: rotate(315deg);
            -o-transform: rotate(315deg);
            -webkit-transform: rotate(315deg);
            -ms-transform: rotate(315deg);
            transform: rotate(315deg); } }
    @media (max-width: 1199px) {
      .product_list .product-image-container .quick-view-wrapper-mobile .quick-view-mobile:hover {
        background-color: rgba(lighten(theme-color(black),30%), 0.57); } }
    @media (max-width: 1199px) {
      .product_list .product-image-container .quick-view-wrapper-mobile {
        display: block;
        background-color: transparent;
        height: 155px;
        width: 215px;
        position: absolute;
        overflow: hidden;
        pointer-events: none;
        bottom: 0px;
        right: 0px; } }
  .product_list .product-image-container .quick-view {
    font: 700 13px/16px Arial, Helvetica, sans-serif;
    color: lighten(theme-color(black),30%);
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -21px 0 0 -53px;
    padding: 13px 0 0;
    background: rgba(255, 255, 255, 0.82);
    width: 107px;
    height: 43px;
    text-align: center;
    -moz-box-shadow: rgba(theme-color(black), 0.16) 0 2px 8px;
    -webkit-box-shadow: rgba(theme-color(black), 0.16) 0 2px 8px;
    box-shadow: rgba(theme-color(black), 0.16) 0 2px 8px; }
    @media (max-width: 1199px) {
      .product_list .product-image-container .quick-view {
        display: none; } }
    @media (min-width: 1200px) {
      .product_list .product-image-container .quick-view {
        display: none; } }
    @media (max-width: 767px) {
      .product_list .product-image-container .quick-view {
        display: none; } }
.product_list .comments_note {
  text-align: left;
  overflow: hidden; }
  .product_list .comments_note .star_content {
    float: left; }
  .product_list .comments_note .nb-comments {
    overflow: hidden;
    font-style: italic; }
.product_list .functional-buttons div a, .product_list .functional-buttons div label {
  font-weight: bold;
  color: lighten(theme-color(black),30%);
  cursor: pointer; }
  .product_list .functional-buttons div a:hover, .product_list .functional-buttons div label:hover {
    color: #000; }
.product_list .functional-buttons div.wishlist {
  border-right: 1px solid lighten(theme-color(black),75%); }
  .product_list .functional-buttons div.wishlist a:before {
    display: inline-block;
    font-family: "FontAwesome";
    content: "\f08a";
    margin-right: 3px;
    padding: 0 3px; }
  .product_list .functional-buttons div.wishlist a.checked:before {
    content: "\f004"; }
  @media (min-width: 992px) and (max-width: 1199px) {
    .product_list .functional-buttons div.wishlist {
      border-right: 0; }
      .product_list .functional-buttons div.wishlist a:before {
        display: none; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .product_list .functional-buttons div.wishlist {
      border-right: 0; }
      .product_list .functional-buttons div.wishlist a:before {
        display: none; } }
.product_list .functional-buttons div.compare a:before {
  content: "\f067";
  display: inline-block;
  font-family: "FontAwesome";
  margin-right: 3px; }
  @media (min-width: 992px) and (max-width: 1199px) {
    .product_list .functional-buttons div.compare a:before {
      display: none; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .product_list .functional-buttons div.compare a:before {
      display: none; } }
.product_list .functional-buttons div.compare a.checked:before {
  content: "\f068"; }

/*******************************************************
Product list(Grid) Styles
********************************************************/
.product_list.grid .item {
  padding-bottom: 30px;
  text-align: center;
  position: relative;
}

.product_list.grid .item .product-container {
	/*min-height: 540px;*/
	background: theme-color(white);
	padding: 15px;
	position: relative;
	-moz-transition:all linear .2s;
	-ms-transition:all linear .2s;
	-o-transition:all linear .2s;
	-webkit-transition:all linear .2s;
	transition:all linear .2s; 
  }
	.product_list.grid .item .product-container:hover{
		-moz-box-shadow:inset 0px 0px 0px 3px rgba(theme-color(black),0.2);
		-webkit-box-shadow:inset 0px 0px 0px 3px rgba(theme-color(black),0.2);
		-o-box-shadow:inset 0px 0px 0px 3px rgba(theme-color(black),0.2);
		box-shadow:inset 0px 0px 0px 3px rgba(theme-color(black),0.2);
	}


    .product_list.grid .item .product-container .product-image-container {
      margin-bottom: 13px;
      position: relative; }
      @media (max-width: 767px) {
        .product_list.grid .item .product-container .product-image-container {
          margin-left: auto;
          margin-right: auto; } }
      .product_list.grid .item .product-container .product-image-container .content_price {
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        background: url(../img/price-container-bg.png);
        padding: 9px 0;
        display: none; }
        .product_list.grid .item .product-container .product-image-container .content_price span {
          color: theme-color(white); }
          .product_list.grid .item .product-container .product-image-container .content_price span.old-price {
            color: lighten(theme-color(black),70%); }
    .product_list.grid .item .product-container h5 {
      padding: 0 15px 7px 15px;
      min-height: 53px; }
    @media (min-width: 1200px) {
      .product_list.grid .item .product-container .comments_note {
        display: none; } }
    .product_list.grid .item .product-container .comments_note .star_content {
      margin: 0 3px 12px 59px; }
    .product_list.grid .item .product-container .product-desc {
      display: none; }
    .product_list.grid .item .product-container .content_price {
      padding-bottom: 9px;
    }
    
    .product_list.grid .item .product-container .price{
      display: block;
    }

    .product_list.grid .item .product-container .old-price,
    .product_list.grid .item .product-container .price-percent-reduction {
      display: inline-block; }
    .product_list.grid .item.product-container .product-flags {
      display: none; }
    .product_list.grid .item .product-container .old-price {
      margin-right: 5px; }
    
  .price-without-tax{
    display: inline-block;
  }
      
	.product_list.grid .item .product-container .button-container .btn{
		display: block;
		font-family: filson-pro, sans-serif;
    font-weight: 700;
		text-transform: uppercase;
		border-radius:20px;
		border: transparent solid 3px;
	}
	.product_list.grid .item .product-container .button-container .btn.add_to_cart_button{
		border-color:theme-color(secondary);
		background: transparent;
		color: theme-color(secondary);
		margin-bottom: 15px;
	}
	.product_list.grid .item .product-container .button-container .btn.add_to_cart_button:hover{
		background:theme-color(secondary);
		color: theme-color(white);
	}
	.product_list.grid .item .product-container .button-container .btn.lnk_view{
		background: lighten(theme-color(black),90%);
		border-color:lighten(theme-color(black),90%);
		color: lighten(theme-color(black),30%);
	}
	.product_list.grid .item .product-container .button-container .btn.lnk_view:hover{
		background: lighten(theme-color(black),30%);
		border-color:lighten(theme-color(black),30%);
		color: lighten(theme-color(black),90%);
	}
    .product_list.grid .item .product-container .functional-buttons {
      background: url(../img/functional-bt-shadow.png) repeat-x;
      padding: 11px 0 5px; }
      @media (min-width: 1200px) {
        .product_list.grid .item .product-container .functional-buttons {
          display: none; } }
      .product_list.grid .item .product-container .functional-buttons div {
        width: 50%;
        float: left;
        padding: 3px 0 4px 0; }
  @media (min-width: 1200px) {
    .product_list.grid .item.hovered .product-container {
      -moz-box-shadow: rgba(0, 0, 0, 0.17) 0 0 13px;
      -webkit-box-shadow: rgba(0, 0, 0, 0.17) 0 0 13px;
      box-shadow: rgba(0, 0, 0, 0.17) 0 0 13px;
      position: relative;
      z-index: 10; }
      .product_list.grid .item.hovered .product-container .content_price {
        display: none; }
      .product_list.grid .item.hovered .product-container .product-image-container .content_price {
        display: block; }
      .product_list.grid .item.hovered .product-container .product-image-container .quick-view {
        display: block; }
      .product_list.grid .item.hovered .product-container .functional-buttons,
      .product_list.grid .item.hovered .product-container .button-container,
      .product_list.grid .item.hovered .product-container .comments_note {
        display: block; } }
  @media (min-width: 992px) {
    body:not(.index) .product_list.grid .item.first-in-line {
      clear: left; } }
  @media (min-width: 480px) and (max-width: 991px) {
    .product_list.grid .item.first-item-of-tablet-line {
      clear: left; } }
.product_list.grid li.hovered h5 {
  min-height: 30px; }

@media (min-width: 1200px) {
  #blockpack ul .item.last-line {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0; } }
@media (min-width: 480px) and (max-width: 767px) {
  #blockpack ul .item.first-item-of-tablet-line {
    clear: none; }
  #blockpack ul .item.first-item-of-mobile-line {
    clear: left; } }

/*******************************************************
Product list(List) Styles
********************************************************/
@media (max-width: 479px) {
  .product_list.list .item .left-block {
    width: 100%; } }
.product_list.list .item .product-container {
  border-top: 1px solid lighten(theme-color(black),75%);
  padding: 30px 0 30px; }
.product_list.list .item .product-image-container {
  position: relative;
  border: 1px solid ;
  padding: 9px; }
  @media (max-width: 479px) {
    .product_list.list .item .product-image-container {
      max-width: 290px;
      margin: 0 auto; } }
  .product_list.list .item .product-image-container .content_price {
    display: none !important; }
.product_list.list .item .product-flags {
  color: lighten(theme-color(black),20%);
  margin: -5px 0 10px 0; }
  .product_list.lis .item .product-flags .discount {
    color: theme-color(red); }
.product_list.list .item h5 {
  padding-bottom: 8px; }
.product_list.list .item .product-desc {
  margin-bottom: 15px; }
@media (max-width: 479px) {
  .product_list.list .item .center-block {
    width: 100%; } }
.product_list.list .item .center-block .comments_note {
  margin-bottom: 12px; }
@media (min-width: 992px) {
  .product_list.list .item .right-block .right-block-content {
    margin: 0;
    border-left: 1px solid lighten(theme-color(black),75%);
    padding-left: 15px;
    padding-bottom: 16px; } }
@media (max-width: 991px) {
  .product_list.list .item .right-block .right-block-content {
    padding-top: 20px; } }
@media (max-width: 479px) {
  .product_list.list .item .right-block .right-block-content {
    padding-top: 5px; } }
.product_list.list .item .right-block .right-block-content .content_price {
  padding-bottom: 10px; }
  @media (max-width: 991px) {
    .product_list.list .item .right-block .right-block-content .content_price {
      padding-top: 13px;
      padding-bottom: 0; } }
  @media (max-width: 479px) {
    .product_list.list .item .right-block .right-block-content .content_price {
      padding-top: 0;
      width: 100%; } }
  .product_list.list .item .right-block .right-block-content .content_price .price {
    display: inline-block;
    margin-top: -4px;
    margin-bottom: 14px; }
    .product_list.list .item .right-block .right-block-content .content_price .price.old-price {
      margin-right: 8px; }
.product_list.list .item .right-block .right-block-content .button-container {
  overflow: hidden;
  padding-bottom: 20px; }
  @media (max-width: 479px) {
    .product_list.list .item .right-block .right-block-content .button-container {
      width: 100%; } }
  .product_list.list .item .right-block .right-block-content .button-container .btn {
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .product_list.list .item .right-block .right-block-content .button-container .btn {
        float: left;
        clear: both; } }
@media (min-width: 992px) {
  .product_list.list .item .right-block .right-block-content .functional-buttons {
    overflow: hidden; } }
@media (max-width: 991px) {
  .product_list.list .item .right-block .right-block-content .functional-buttons {
    clear: both; }
    .product_list.list .item .right-block .right-block-content .functional-buttons > div {
      float: left;
      padding-top: 0 !important;
      padding-right: 20px; } }
@media (max-width: 479px) {
  .product_list.list .item .right-block .right-block-content .functional-buttons {
    float: none;
    display: inline-block; }
    .product_list.list .item .right-block .right-block-content .functional-buttons a i, .product_list.list .item .right-block .right-block-content .functional-buttons a:before, .product_list.list .item .right-block .right-block-content .functional-buttons label i, .product_list.list .item .right-block .right-block-content .functional-buttons label:before {
      display: none !important; } }
.product_list.list .item .right-block .right-block-content .functional-buttons a {
  cursor: pointer; }
.product_list.list .item .right-block .right-block-content .functional-buttons .wishlist {
  border: none; }
.product_list.list .item .right-block .right-block-content .functional-buttons .compare {
  padding-top: 10px; }
  .product_list.list .item .right-block .right-block-content .functional-buttons .compare a:before {
    margin-right: 10px; }
@media (min-width: 1200px) {
  .product_list.list .item:hover .product-image-container .quick-view {
    display: block; } }
@media (max-width: 479px) {
  .product_list.list .item {
    text-align: center; } }

/*******************************************************
Product list(Index page) Styles
********************************************************/
#index .product_list.tab-pane > li {
  padding-bottom: 10px;
  margin-bottom: 10px; }
  @media (min-width: 1200px) {
    #index .product_list.tab-pane > li {
      margin-bottom: 30px; } }
  @media (min-width: 1200px) {
    #index .product_list.tab-pane > li.last-line {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0; } }
  @media (min-width: 480px) and (max-width: 767px) {
    #index .product_list.tab-pane > li.first-item-of-tablet-line {
      clear: none; }
    #index .product_list.tab-pane > li.first-item-of-mobile-line {
      clear: left; } }

/***** IE code *****/
.ie8 .quick-view {
  background: theme-color(white);
  border: 1px solid lighten(theme-color(black),75%); }
